<template>
  <div class="">
    <div class="card-table">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2">
                Договора(Клиентов)
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <el-input
                    placeholder="поиск"
                    prefix-icon="el-icon-search"
                    v-model="sarcheBlocks"
                    size="small"
                    round
                    style="width: 100% !important"
                  ></el-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <el-button size="small" class="btu-color" @click="appModal = true">
              Создать
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table class="table-my-code table-bordered">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.contractNomber.show">
                {{ columns.contractNomber.title }}
              </th>

              <th v-if="columns.clientsName.show">
                {{ columns.clientsName.title }}
              </th>

              <th v-if="columns.dataContract.show">
                {{ columns.dataContract.title }}
              </th>

              <th v-if="columns.status.show">{{ columns.status.title }}</th>

              <th v-if="columns.summa.show">{{ columns.summa.title }}</th>

              <th v-if="columns.coment.show">
                {{ columns.coment.title }}
              </th>

              <th v-if="columns.data.show">
                {{ columns.data.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.contractNomber.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.contractNomber"
                  :placeholder="columns.contractNomber.title"
                ></el-input>
              </th>

              <th v-if="columns.clientsName.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.clientsName"
                  :placeholder="columns.clientsName.title"
                >
                </el-input>
              </th>
              <th v-if="columns.dataContract.show">
                <el-date-picker
                  :placeholder="columns.dataContract.title"
                  v-model="filterForm.dataContract"
                  size="mini"
                >
                </el-date-picker>
              </th>
              <th v-if="columns.status.show">
                <el-select
                  v-model="filterForm.status"
                  filterable
                  clearable
                  :placeholder="columns.status.title"
                  size="mini"
                >
                  <el-option label="Активний" value="shanghai"></el-option>
                  <el-option label="Не астивний" value="beijing"></el-option>
                </el-select>
              </th>
              <th v-if="columns.summa.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.summa"
                  :placeholder="columns.summa.title"
                ></el-input>
              </th>
              <th v-if="columns.coment.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.coment"
                  :placeholder="columns.coment.title"
                ></el-input>
              </th>
              <th v-if="columns.data.show">
                <el-date-picker
                  :placeholder="columns.data.title"
                  v-model="filterForm.data"
                  size="mini"
                >
                </el-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="user in list" :key="user.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ user.id }}</td>
              <td v-if="columns.contractNomber.show">
                {{ user.contractNomber }}
              </td>
              <td v-if="columns.clientsName.show">
                {{ user.clientsName }}
              </td>
              <td v-if="columns.dataContract.show">
                {{ user.dataContract }}
              </td>
              <td v-if="columns.status.show">
                {{ user.status === "active" ? "Активний" : "Не астивний" }}
              </td>
              <td v-if="columns.summa.show">
                {{ user.summa }}
              </td>
              <td v-if="columns.coment.show">
                {{ user.coment }}
              </td>
              <td v-if="columns.data.show">{{ user.data }}</td>

              <td v-if="columns.settings.show" class="settings-td">
                <el-dropdown :hide-on-click="false" class="table-bottons-right">
                  <span class="el-dropdown-link more_icons">
                    <i class="el-icon-arrow-down el-icon-more-outline"></i>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="dropdown-menumy-style"
                  >
                    <el-dropdown-item icon="el-icon-edit">
                      Изменить
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-delete">
                      Удалить
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <Pagination />
        </div>
      </div>

      <!-- modal right forms -->
      <!-- <el-drawer
        class="right-modal"
        :with-header="false"
        :append-to-body="true"
        :visible.sync="drawer"
      >
        <CrmCreate />
      </el-drawer> -->

      <div class="app-modal app-modal__full modal-widt-40">
        <el-dialog :visible.sync="appModal" :fullscreen="true" width="">
          <div
            @click="appModal = false"
            class="close-modal-stick d-flex align-center p-fixed text-white p-2 font-bold pointer transition"
          >
            <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
            </div>
            <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <CrmCreate />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
export default {
  name: "contract",
  components: {
    CrmCreate,
    Pagination,
  },

  data() {
    return {
      appModal: false,
      currentPage2: 5,
      checkList: [],
      form: {},
      // drawer: false,
      sarcheBlocks: "",

      filterForm: {
        id: "",
        contractNomber: "",
        clientsName: "",
        dataContract: "",
        status: "",
        data: "",
        summa: "",
        coment: "",
      },
      list: [
        {
          id: "1",
          contractNomber: "21",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "2",
          contractNomber: "22",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "3",
          contractNomber: "23",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "4",
          contractNomber: "24",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "5",
          contractNomber: "25",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "6",
          contractNomber: "26",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "7",
          contractNomber: "27",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "8",
          contractNomber: "28",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "29",
          contractNomber: "29",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "10",
          contractNomber: "30",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "11",
          contractNomber: "31",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "12",
          contractNomber: "32",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "13",
          contractNomber: "33",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "14",
          contractNomber: "34",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "15",
          contractNomber: "35",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "16",
          contractNomber: "36",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
        {
          id: "17",
          contractNomber: "37",
          clientsName: "Oa Покупатель",
          dataContract: "12.12.2020",
          status: "Активный",
          data: "12.12.2020",
          summa: "100 UZS",
          coment: "New Test",
        },
      ],
      columns: {
        id: {
          show: true,
          title: "№",
          sortable: true,
          column: "id",
        },
        contractNomber: {
          show: true,
          title: "Договор №",
          sortable: true,
          column: "contractNomber",
        },
        clientsName: {
          show: true,
          title: "Клиент",
          sortable: true,
          column: "clientsName",
        },
        dataContract: {
          show: true,
          title: "Дата ",
          sortable: true,
          column: "dataContract",
        },
        status: {
          show: true,
          title: "Статус ",
          sortable: true,
          column: "status",
        },
        summa: {
          show: true,
          title: "Сумма",
          sortable: true,
          column: "summa",
        },
        coment: {
          show: true,
          title: "Комментарий:",
          sortable: true,
          column: "coment",
        },
        data: {
          show: true,
          title: "Дата создания ",
          sortable: true,
          column: "data",
        },
        settings: {
          show: true,
          title: "Профиль",
          sortable: false,
          column: "settings",
        },
      },
    };
  },
  // methods: {
  //   openDrawer() {
  //     this.appModal = !this.appModal;
  //   },
  // },
};
</script>

